import React from "react";
import ha from "../assets/ha.jpg"
import { LuMoveLeft } from "react-icons/lu";

export default function Ha() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96 rounded-2xl object-cover"
            src={ha}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            General Medicine (HA)
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            General Medicine (HA)
          </div>
          <div className=" text-[17px] text-gray-700">
            HA छात्र तथा छात्रा दुवै वर्गले पढ्न पाउने विषय हो । HA को पढाई ३
            वर्षको हुने गर्दछ । यो विषय पढिसकेपछि लोक सेवा उत्तीर्ण गरी सामान्य
            चिकित्सकको रुपमा विभिन्न स्वास्थ्य संस्थामा विरामी जाँच गर्न पाईन्छ।
            `HA पढिसकेपछि MBBS मा पनि जान सकिने भएकाले अहिले यो विषयमा
            विद्यार्थीहरुको आकर्षण बढ्दै गएको छ । कडा प्रतिस्पर्धा बीच मात्र
            पढ्न पाईने भएकाले समयमै तयारी गर्नु राम्रो मानिन्छ ।
          </div>
          <div className=" text-[17px] text-gray-700">
            जागिरको लागि यो विषय उत्कृष्ट रहेकोले प्रतिस्पर्धामा आफ्नो सिट
            सुनिश्चित गर्न समयको सदुपयोग गरी SEE सकिना साथ तयारीमा लाग्न छात्र
            तथा छात्राहरुलाई प्रेरित गर्दछौं । HA विषय CTEVT बाट सम्बन्धन लिएका
            Colleges मा मात्र पढ्न पाईन्छ ।
          </div>

          <div className=" flex flex-col gap-2">
          <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Our Facilities
            </p>
            <p>Physical class</p>
            <p>Online class </p>
            <p>Online form fill-up </p>
            <p>Audio visual classes </p>
            <p>Daily test </p>
            <p>More details:</p>
            <p className="  mb-4">9851020006 , 9841761641 , 01-4168305</p>

            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <a
          href="/allCourses"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
