import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { IoStarHalf } from "react-icons/io5";
import girl from "../assets/girl.png";

export default function Testmonirals() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" md:flex  justify-between  md:w-10/12 w-full">
        <div
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="fade-left"
          className=" flex flex-col md:gap-24 gap-4 md:flex-1 md:border-r md:pr-0 pr-4"
        >
          <span className="  md:text-3xl text-2xl text-black  font-bold">
            Hear Reviews From Students
          </span>
          {/* <span className="  text-[17px] text-gray-700  pb-2 ">
            रिलायवल एकेडेमी प्रा.लि. एक विशुद्ध शैक्षिक संस्था हो । विद्यार्थीको
            स्कूल अध्ययन पश्चात् उच्च अध्ययनका लागि पुल (Bridge ) को काम गरिदिने
            उद्धेश्यले यस संस्था स्थापना गरिएको हो । SEE पछि विद्यार्थी वर्गको
            रोजाइको विषय अध्ययन गर्न प्रवेश परीक्षाको तयारीमा सहयोगी भूमिका
            निर्वाह गर्ने यस संस्थाको मुख्य लक्ष्य रहेको छ ।
          </span> */}
          <div className="  flex flex-col gap-3 bg-primary-550 hover:bg-primary-500 cursor-pointer duration-500 md:w-max md:px-16 px-4 py-7 rounded-2xl">
            <span className=" flex justify-center text-gray-400 font-semibold text-2xl">
              Average Rating Now
            </span>
            <span className=" flex justify-center items-center gap-3">
              <p className=" text-gray-400 font-semibold  text-4xl">4.8</p>
              <span className="  text-primary-300 grid grid-cols-4 gap-2">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <IoStarHalf />
              </span>
            </span>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  md:flex-1"
        >
          <section className=" md:pt-0 pt-10">
            <div className="max-w-screen-xl mx-auto  md:px-4">
              <div className="max-w-3xl mx-auto text-center">
                <h3 className="  text-black  font-bold  pb-4 md:pb-7 text-2xl md:text-3xl">
                  What Students are saying
                </h3>
                <ul>
                  {testimonials.map((item, idx) =>
                    currentTestimonial === idx ? (
                      <li key={idx}>
                        <figure>
                          <blockquote>
                            <p className="text-gray-700  text-[17px]">
                              {item.quote}
                            </p>
                          </blockquote>
                          <div className="mt-8">
                            <img
                              alt=""
                              src={item.avatar}
                              className="w-16 h-16 mx-auto rounded-full object-cover cursor-pointer"
                            />
                            <div className="mt-4">
                              <span className="block text-gray-600 font-semibold italic">
                                {item.name}
                              </span>
                              {/* <span className="block text-gray-600 text-sm mt-0.5">
                                {item.title}
                              </span> */}
                            </div>
                          </div>
                        </figure>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
              <div className="mt-6">
                <ul className="flex gap-x-3 justify-center">
                  {testimonials.map((item, idx) => (
                    <li key={idx}>
                      <button
                        className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-indigo-600 focus:ring ${
                          currentTestimonial === idx
                            ? "bg-indigo-600"
                            : "bg-gray-300"
                        }`}
                        onClick={() => setCurrentTestimonial(idx)}
                      ></button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const testimonials = [
  {
    avatar: girl,
    name: "Sita Karki ",
    // title: "Product designer",
    quote:
      "मैले सरकारी विद्यालयमा पढेकी हुँ । ममा सानै देखि नर्स बन्ने ईच्छा थियो । SEE परीक्षाको दिन मेरो हातमा नर्सिङ्ग विषय सम्बन्धि जानकारी भएको Booklet हात पर्यो, पढ्दै जाँदा CTEVT अन्तर्गतका College हरुमा सरकारी विद्यालयमा पढ्ने छात्राहरुको लागि निःशुल्क सिट छुट्याएको थाहा पाएँ । तयारी कक्षा बिना नाम निकाल्न अप्ठारो रहेछ त्यसकारण म विभिन्न इन्टिच्यूटमा बुझ्न गएँ तर अन्त्यमा Reliable Academy लाई रोजी तयारीमा लागे ।",
  },
  {
    avatar: girl,
    name: "Pudima Tamang",
    // title: "Founder of meta",
    quote:
      "मेरो भविष्यको आधार Reliable Academy हो । मैले काठमाडौको नेपालटार स्थित हिमरश्मी मा. वि.मा पढेकी हुँ । पढाई अवधिमा भविष्यमा मैले के पढ्ने, के बन्ने भन्ने कुरा खासै सोचेकि थिईन। SEE सुरु हुनु भन्दा केहि दिन अगाडि आफ्नै विद्यालयको शिक्षकद्वारा भएको Career Counseling मा छात्राहरुको लागि भविष्य सुनिश्चित हुने विषय Nursing रहेको भन्ने बुझेपछि मैले आफूले पनि Nursing नै पढ्ने अठोट गरें।अन्त्यमा Reliable Academy लाई रोजी तयारीमा लागे ",
  },

  {
    avatar: girl,
    name: "Shanti Mijar",
    // title: "DevOp engineer",
    quote:
      "  म दोलखाको मालुङ गाउँपालीका स्थित श्री गोलमेश्वर सेकेन्डरी स्कुलबाट SEE परीक्षा सकेर बसिरहेकी थिएँ । म दलित परिवारकी छोरी   हुँ । परिवारको न्यून आयका कारण २/४ पैसा आउला भनि पढाउन जान सोचिरहेकी थिएँ । एक दिन हाम्रै गाउँको सिर्जना आन्टी आउनु भयो र तुरुन्त मेरो परिवार सँग सल्लाह गरी काठमाडौं जाने निधो गराउनु भायो । आन्टीको राम्रो ज्ञानको कारण सोझै मलाई पुतलीसडक स्थित Reliable Academy मा बोलाउनु भयो र त्यहीँ तयारीमा लागे,  अहिले परीक्षा दिँदै गरेका सबैलाई SEE पछिको समयको  सहि सदुपयोग गर्न Reliable Academy मा सम्पर्क गर्न सल्लाह दिन चाहन्छु ।",
  },
];
