import React from "react";
import mam from "../assets/mam.png";

export default function WhyChoose() {
  return (
    <div className="  md:px-0 px-4 flex flex-col gap-10 justify-center items-center">
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className=" flex flex-col justify-center items-center gap-6  "
      >
        <p className=" cursor-pointer  text-black  font-bold md:text-3xl text-xl  curve-underline">
          WHY CHOOSE RELIABLE ACADEMY ?
        </p>
        <p className=" md:text-[18px] text-[17px] text-gray-700 ">
          रिलायवल एकेडेमी प्रा.लि. एक विशुद्ध शैक्षिक संस्था हो । विद्यार्थीको
          स्कूल अध्ययन पश्चात् उच्च अध्ययनका लागि पुल (Bridge ) को काम गरिदिने
          उद्धेश्यले यस संस्था स्थापना गरिएको हो ।
        </p>
      </div>
      <div className=" md:flex justify-between gap-10  md:w-10/12">
        <div
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="fade-left"
          className=" flex-1 "
        >
          <img
            className=" rounded-3xl md:h-[65vh] h-96   cursor-pointer object-cover"
            src={mam}
            alt=""
          />
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex-1 my-8"
        >
          <div className="   grid gap-8">
            {icons.map((icon, index) => (
              <div
                key={index}
                className=" cursor-pointer group flex items-center gap-4"
              >
                <p className=" object-cover cursor-pointer  text-xl text-primary-450">
                  ({icon.no})
                </p>
                <span className=" md:text-[18px] text-[17px] text-gray-600 ">
                  {icon.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const icons = [
  {
    no: "1",
    name: "दक्ष  तथा अनुभवी शिक्षक तथा शिक्षिकाद्वारा पढाईने",
  },
  {
    no: "2",
    name: "विद्यार्थीको Online फारम भरिदिने व्यवस्था ",
  },
  {
    no: "3",
    name: " चार महिने कक्षा, प्रत्येक शनिवार Model परीक्षा",
  },
  {
    no: "4",
    name: "निःशुल्क पुस्तक",
  },
  {
    no: "5",
    name: "हरेक दिन आधाघण्टाको Class Test ",
  },
  {
    no: "6",
    name: " विद्यालयले कक्षा १० मा लिने आन्तरीक परीक्षामा सर्वोत्कृष्ट अंक वा ग्रेडि ल्याउने तथा अति दुर्गम क्षेत्रका विपन्न छात्र / छात्रालाई छात्रवृत्तीको व्यवस्था",
  },
  {
    no: "7",
    name: "आवश्यकता परेमा Hostel को ब्यवस्था",
  },
];
