import React from "react";
import mata from "../assets/mata.png";
import sunil from "../assets/sunil.jpg";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

export default function Executive() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-7 gap-5 justify-center md:items-center md:w-10/12 w-full">
        <div className="  text-black  font-bold md:text-3xl text-2xl ">
          Executive Comittee
        </div>
        <div className=" text-[17px] md:text-[19px] text-gray-600">
          Leadership shaping vision, strategy, and organizational excellence
          with experienced guidance.
        </div>

        <div className="  mt-5 grid lg:grid-cols-2  md:gap-20  gap-8">
          {items.map((item, index) => (
            <Link
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              to={item.path}
            >
              <Tilt
                key={index}
                className=" w-max cursor-pointer flex flex-col gap-3 justify-center items-center border border-gray-300 py-5 px-10 rounded-xl"
              >
                <img
                  className="   md:h-80 md:w-80 w-72 h-72 object-cover  rounded-md"
                  src={item.img}
                  alt=""
                />
                <h1 className=" flex justify-center items-center border-b  border-gray-300 w-full pb-2 text-xl text-gray-700 font-semibold">
                  {item.Name}
                </h1>
                <p className=" md:text-[19px] text-[18px] text-gray-700">
                  {item.Position}
                </p>
              </Tilt>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

const items = [
  {
    img: mata,
    Name: "Matrika Bhattarai",
    Position: "Founder/Director",
    path: "/matarika",
  },
  {
    img: sunil,
    Name: "Sunil Bhattarai",
    Position: "Director",
    path: "/sunil",
  },
];
