import React from "react";
import shot from "../assets/st1.jpg";

export default function Demo() {
  const backgroundImageUrl = shot;

  return (
    <div className="bg-gray-100">
      {/* Full-screen section with fixed background image */}
      <div
        className="relative h-[60vh] bg-fixed bg-center bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="absolute inset-0   bg-black opacity-60"></div>


        {/* Overlay */}
        <div className="relative md:px-0 px-4 z-10 flex flex-col items-center justify-center h-full ">
          <h1
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="md:text-4xl text-3xl text-gray-100 font-bold tracking-widest italic cursor-pointer"
          >
            Reliable Academy is the top professional learning center.
          </h1>
          <p
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            className="text-lg md:mt-8  mt-6 text-gray-200 md:max-w-5xl"
          >
            Reliable Academy is a leading professional learning center, known
            for its expert instructors and diverse course offerings. It provides
            students with the skills and knowledge they need to succeed in their
            careers and results-oriented learning environment.
          </p>
        </div>
      </div>
    </div>
  );
}
