import React from "react";
import lab from "../assets/lab.jpg"
import { LuMoveLeft } from "react-icons/lu";

export default function LabTechnician() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96 rounded-2xl object-cover"
            src={lab}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            LAB TECHNICIAN
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            LAB TECHNICIAN
          </div>
          <div className=" text-[17px] text-gray-700">
            व्यवसायिक विषयमा Lab Technician को महत्वपूर्ण स्थान रहेको छ । SEE
            पछि रोजगारीको विषयमा धेरै छात्र/छात्राहरुको रोजाइँमा यो विषय पनि
            राम्रो मानिन्छ । अस्पतालका विरामीको जाँचको लागि अति महत्वपूर्ण
            भूमिका रहेको Lab Technician हरुले सहजै जागिर पाउनका साथै आफैले पनि
            व्यवसाय सञ्चालन गर्न सक्दछन् । SEE पछि ३ वर्षे यो विषय केवल CTEVT
            मातहतका Colleges हरुमा मात्र पढ्न पाईन्छ । थप जानकारीका लागि
            Reliable Academy मा सम्पर्क गर्नुहुन अनुरोध छ ।
          </div>

          <div className=" flex flex-col gap-2">
          <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Our Facilities
            </p>
            <p>Physical class</p>
            <p>Online class </p>
            <p>Online form fill-up </p>
            <p>Audio visual classes </p>
            <p>Daily test </p>
            <p>More details:</p>
            <p className="  mb-4">9851020006 , 9841761641 , 01-4168305</p>

            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <a
          href="/allCourses"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
