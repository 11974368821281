import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function LokBscNurse() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
          B.Sc. Nursing
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
            To prepare for the Loksewa exam as a B.Sc. Nursing graduate, start
            by understanding the exam syllabus, covering subjects like Nepali,
            English, General Knowledge, and nursing-specific topics. Create a
            consistent study plan, focusing on key areas and revisiting past
            exam papers to grasp the format and question types. Consider joining
            a Loksewa preparation class or study group for additional guidance.
            Stay updated on current affairs, particularly in health and medical
            fields, by reading newspapers and medical journals. Finally,
            maintain a balanced lifestyle with proper nutrition, exercise, and
            rest to keep your mind sharp and ready for the exam.
          </div>
        </div>
        <a
          href="/loksewa"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
