import React from "react";
import Testmonirals from "../components/Testmonirals";
import WhyChoose from "./../components/WhyChoose";
import Courses from "./../components/Courses";
import QuickAnswer from "../components/QuickAnswer";
import Count from "../components/Count";
import Herosection from "../components/Herosection";
import Demo from "../components/Demo";

export default function Home() {
  return (
    <div className=" overflow-x-hidden flex flex-col md:gap-32 gap-20">
      <Herosection />
      <Testmonirals />
      <Count />
      <WhyChoose />
      <Courses />
      <Demo/>
      <QuickAnswer />
    </div>
  );
}
