import React from "react";
import { BiSolidPhoneCall } from "react-icons/bi";

export default function Message() {
  return (
    <div className=" px-2 md:px-5 flex z-30 fixed flex-col top-[87%]   right-0">
      <ul className=" flex  flex-col gap-4 px-2">
        <li className="  bg-primary-500 hover:bg-primary-550 border border-gray-400 text-white font-semibold px-3 py-3 rounded-full hover:scale-105 duration-500 cursor-pointer">
          <a
            className=" "
            href="tel: 01-4168305"
            target="_blank"
            rel="noreferrer"
          >
            <BiSolidPhoneCall size={27} />
          </a>
        </li>
      </ul>
    </div>
  );
}
