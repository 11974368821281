import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function StaffNurse() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Staff Nurse
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            Staff Nurse
          </div>
          <div className=" text-[17px] text-gray-700">
            Staff Nurse नेपालमा छात्राहरुले मात्र पढ्न पाउने विषय थियो । हाल
            छात्रहरुले पनि पढ्न पाउने व्यवस्था गरिएको छ। नर्सिङ्ग विषयको पढाई
            अवधी ३ वर्षको हुने गर्दछ । वर्षको एक पटक मात्र नर्सिङ्ग विषयको
            प्रवेश परीक्षाको फारम खुल्दछ । प्रत्येक वर्षको साउन र भाद्र महिनामा
            प्रवेश परीक्षा हुने गरेको छ । प्रवेश परीक्षामा उत्कृष्ट अङ्क ल्याउने
            मध्ये CTEVT अन्तर्गतका College मा ४० जनाको दरले मात्र Staff Nurse
            पढ्न पाईन्छ ।
          </div>
          <div className=" text-[17px] text-gray-700">
            हाल जागिरको लागि स्वदेश तथा विदेशमा यो विषयको माग बढ्दै गएकाले छात्र
            तथा छात्राहरुको रोजाईमा नर्सिङ्ग विषय रहन गएको छ । SEE को अन्तिम
            परीक्षा पश्चात तुरुन्त तयारीमा लाग्नेले प्रायः यो विषयमा नाम निकाल्न
            सफल भएका छन् । यस Reliable Academy मा यहि विषयका दक्ष तथा अनुभवि
            शिक्षक / शिक्षिकाद्वारा पढ़ाईने भएकाले अबको पढ्न पाउने पालो तपाईको
            लागि बनाउन हामी अग्रसर छौं । तर्सथ Reliable Academy मा समयमै भर्ना
            भई आफ्नो भविष्य सुनिश्चित गर्नु हुन अनुरोध गर्दछौँ ।
          </div>

          <div className=" flex flex-col gap-2">
          <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Our Facilities
            </p>
            <p>Physical class</p>
            <p>Online class </p>
            <p>Online form fill-up </p>
            <p>Audio visual classes </p>
            <p>Daily test </p>
            <p>More details:</p>
            <p className="  mb-4">9851020006 , 9841761641 , 01-4168305</p>

            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <a
          href="/allCourses"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
