import React from "react";

export default function About() {
  return (
    <div className="  md:px-0 px-4 flex  justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full shadow-md md:px-10 px-4 pb-10">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  text-black  font-bold md:text-3xl text-2xl "
        >
          About Reliable Academy
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" text-[18px] text-gray-700"
        >
          Reliable Academy stands as a symbol of excellence in academic
          preparation. We are dedicated to helping students excel in various
          fields, especially Nursing and Medical subjects. Addressing the needs
          of students extending from SEE to MN levels, we specialize in thorough
          guidance and comprehensive materials tailored for entrance
          examination, License and Loksewa preparation. Our experienced
          teacher’s team works hard to create resources that help students
          succeed in their chosen fields. Recognizing the intense competition in
          these fields, we emphasize the crucial importance of detailed
          preparation and the application of acquired knowledge to real-world
          contexts. Join Reliable Academy and embark on a journey towards
          realizing your academic and professional ambitions, assured of
          unmatched support and expertise every step of the way.
        </div>
      </div>
    </div>
  );
}
