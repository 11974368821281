import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function LiPharmacy() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            PHARMACY
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
            Preparing for pharmacy licensure involves a mix of academic study,
            practical experience, and exam-focused review. Start by revisiting
            key topics like pharmacology, therapeutics, and pharmacy law,
            ensuring you're solid on foundational knowledge. Gain hands-on
            experience through internships or residencies to apply what you've
            learned. Use study groups, review courses, and practice exams to
            identify and fill knowledge gaps. As the exam approaches, focus on
            time management and stay updated on the latest guidelines. Take care
            of yourself with a balanced schedule to manage stress and optimize
            performance.
          </div>
          <div className=" text-[17px] text-gray-700">
            ३ वर्षको अध्ययन पछि Nepal Pharmacy Council को परीक्षा उर्तीण गरी
            Licence लिनेले औषधी विक्री गर्ने व्यवसाय बनाउन यो विषय उत्तम मानिन्छ
            ।
          </div>
        </div>
        <a
          href="/licence"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
