import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function LokNurse() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Nursing [ 4th , 5th & Officer Level ]
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
            To prepare for Loksewa Nursing examinations (4th, 5th, and Officer
            levels), focus on core nursing subjects like Anatomy, Physiology,
            Medical-Surgical Nursing, Pediatrics, and Obstetrics. It's essential
            to study healthcare policies in Nepal and keep up with current
            affairs. Reviewing previous question papers and practicing with mock
            tests can improve your exam strategy. Create a study plan that
            balances theory and practice, and consider joining group discussions
            or preparatory classes for additional support. Success in these
            exams requires a combination of deep subject knowledge, effective
            time management, and consistent effort.
          </div>
        </div>
        <a
          href="/loksewa"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
