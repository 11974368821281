import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Loksewa() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" shadow-md md:px-10 px-4 pb-4 flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex justify-center text-black  font-bold md:text-2xl text-xl"
        >
          LOKSEWA PREPARATION
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          To prepare for Loksewa nursing examination at the 4th, 5th, and
          Officer levels, candidate's focus should be on key nursing topics such
          as anatomy, physiology, pharmacology, gynecology, midwifery, adult
          health nursing and community health nursing. Reliable Academy's study
          materials; including textbooks, guides, and past papers, are very
          effective to understand the examination's structure and typical
          questions. Reliable Academy conducts practice sessions with mock tests
          frequently to improve candidate's problem-solving as well as time
          management skills. Candidates are required to stay informed about the
          healthcare trends and public health policies for the general knowledge
          section. Joining an institute like Reliable Academy that provides
          required support is crucial in this highly competitive environment.
          With a strategic plan and consistent effort, candidates can definitely
          succeed in the Loksewa nursing examination.
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5  py-10"
        >
          <div className="   grid  md:grid-cols-2  gap-10 items-center">
            <Link
              to="/lokNurse"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              Nursing [ 4th , 5th & Officer Level ]
            </Link>

            <Link
              to="/lokBscNurse"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              B.Sc. Nursing
            </Link>
          </div>
        </div>
        <Link
          to="/"
          className=" cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
