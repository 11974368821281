import React from "react";
import frome from "../assets/forestry.jpg"
import { LuMoveLeft } from "react-icons/lu";

export default function Forestry() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96 rounded-2xl object-cover"
            src={frome}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            DIPLOMA IN FORESTRY
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            DIPLOMA IN FORESTRY
          </div>
          <div className=" text-[17px] text-gray-700">
            SEE उर्तीण गरिसकेपछि CTEVT द्वारा संचालित Diploma in Forestry को
            अध्ययन विद्यार्थीहरुका लागि रुचाइएको विषय हुने गर्दछ । यो कोर्ष
            CTEVT द्वारा संबन्धन प्राप्त केही कलेजहरुमा मात्र संचालित छ ।
            लोकसेवा आयोगद्वारा लिइने रेन्जरको जागिर अथवा विभिन्न NGO/INGO मा
            सजिलै जागिर पाइने भएकाले Diploma in Forestry कार्यक्रम हालका
            वर्षहरुमा विद्यार्थीहरु माझ अति नै आकर्षक विषय बनेको छ ।
          </div>
          <div className=" text-[17px] text-gray-700">
            यो कार्यक्रममा सहभागी हुनको लागि SEE को परीक्षामा English, Maths र
            Science विषय मध्ये कुनै दुईमा C ग्रेड र कुनै एक विषयमा D+ ग्रेड
            प्राप्त गरेको हुनु पर्दछ । जागिरको राम्रो अवसर भएको र कार्यक्रम
            संचालित कलेज थोरै भएकाले प्रवेश परीक्षा निकै कठिन हुँदै गएको हुनाले
            यस कार्यक्रममा अध्ययन गर्न चाहने विद्यार्थी भाइ बहिनीहरुले समयमै आई
            प्रवेश परीक्षाको तयारी कक्षा लिनु नै राम्रो हुनेछ ।
          </div>

          <div className=" flex flex-col gap-2">
          <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Our Facilities
            </p>
            <p>Physical class</p>
            <p>Online class </p>
            <p>Online form fill-up </p>
            <p>Audio visual classes </p>
            <p>Daily test </p>
            <p>More details:</p>
            <p className="  mb-4">9851020006 , 9841761641 , 01-4168305</p>

            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <a
          href="/allCourses"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
