import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function Mn() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            MN
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
            To prepare for the entrance exam for a Master's in Nursing, start by
            understanding the exam structure and key topics, such as advanced
            clinical skills, nursing theory, research methods, and healthcare
            policy. Develop a study plan that balances these areas, and use
            advanced study materials and scholarly articles to deepen your
            knowledge. Joining a study group or finding a mentor can offer
            valuable insights. Regularly practice sample questions to build
            confidence and improve test-taking skills. Stay updated on current
            nursing trends, as this can be useful for both exam questions and
            interview discussions. On exam day, stay calm and confident,
            trusting in your preparation and experience to guide you through the
            test.
          </div>
        </div>
        <a
          href="/entrance"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
