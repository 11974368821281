import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Licence() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" shadow-md md:px-10 px-4 pb-4 flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex justify-center text-black  font-bold md:text-2xl text-xl"
        >
          LICENSURE PREPARATION
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          To become a registered nurse, aspiring candidates who have completed either PCL Nursing or B.Sc. Nursing program must clear the examination conducted by the Nepal Nursing Council. NNC conducts this examination 3 times a year, with a 4-month interval between each examination. Candidates from both backgrounds are required to answer 150 questions, and must score a minimum of 75 marks to pass the examination. Over the years, many students have failed to pass the RN examination conducted by the council. Therefore, it is highly recommended that eligible candidates engage in exam preparation courses, so that their chances of passing the examination will increase significantly.

        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
         Since the introduction of the RN examination by the NNC, Reliable Academy has played a vital role in guiding numerous candidates in achieving success. With an experience of 10 years, Reliable Academy continues to be the most trusted institution, offering excellent resources to the students for their academic improvement and success.

        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex justify-center flex-col md:gap-8 gap-5  py-10"
        >
          <div className="   grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 gap-5">
            <Link
              to="/liStaffNurse"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max w-full md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              STAFF NURSE
            </Link>
            <Link
              to="/bscNurse"
              className=" w-full text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
             B.Sc. NURSING
            </Link>
            <Link
              to="/liPharmacy"
              className=" w-full text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              PHARMACY
            </Link>
            <Link to="/liHa" className=" w-full text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md">
              GENERAL MEDICINE [HA]
            </Link>
          </div>
        </div>
        <Link
          to="/"
          className=" cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
