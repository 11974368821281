import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Home from "./pages.jsx/Home";
import Executive from "./pages.jsx/Executive";
import Matarika from "./pages.jsx/Matarika";
import Sunil from "./pages.jsx/Sunil";
import AllCourses from "./pages.jsx/AllCourses";
import StaffNurse from "./pages.jsx/StaffNurse";
import Enginnering from "./pages.jsx/Enginnering";
import Ha from "./pages.jsx/Ha";
import Forestry from "./pages.jsx/Forestry";
import Pharmacy from "./pages.jsx/Pharmacy";
import LabTechnician from "./pages.jsx/LabTechnician";
import Agriculture from "./pages.jsx/Agriculture";
import Contact from "./pages.jsx/Contact";
import Gallery from "./pages.jsx/Gallery";
import About from "./pages.jsx/About";
import Entrance from "./pages.jsx/Entrance";
import Licence from "./pages.jsx/Licence";
import Loksewa from "./pages.jsx/Loksewa";
import Bridge from "./pages.jsx/Bridge";
import LiStaffNurse from "./pages.jsx/LiStaffNurse";
import BscNurse from "./pages.jsx/BscNurse";
import LiPharmacy from "./pages.jsx/LiPharmacy";
import LiHa from "./pages.jsx/LiHa";
import LokNurse from "./pages.jsx/LokNurse";
import LokBscNurse from "./pages.jsx/LokBscNurse";
import Bn from "./pages.jsx/Bn";
import Mn from "./pages.jsx/Mn";
import Message from "./components/Message";

function App() {
  
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className=" overflow-y-hidden">
      <BrowserRouter>
        <Navbar />
        <div className=" md:py-36 py-28 ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/executive" element={<Executive />} />
            <Route path="/matarika" element={<Matarika />} />
            <Route path="/sunil" element={<Sunil />} />
            <Route path="/allCourses" element={<AllCourses />} />
            <Route path="/staffNurse" element={<StaffNurse />} />
            <Route path="/enginnering" element={<Enginnering />} />
            <Route path="/ha" element={<Ha />} />
            <Route path="/forestry" element={<Forestry />} />
            <Route path="/pharmacy" element={<Pharmacy />} />
            <Route path="/labTechnician" element={<LabTechnician />} />
            <Route path="/agriculture" element={<Agriculture />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
            <Route path="/entrance" element={<Entrance />} />
            <Route path="/licence" element={<Licence />} />
            <Route path="/loksewa" element={<Loksewa />} />
            <Route path="/bridge" element={<Bridge />} />
            <Route path="/liStaffNurse" element={<LiStaffNurse />} />
            <Route path="/bscNurse" element={<BscNurse />} />
            <Route path="/liPharmacy" element={<LiPharmacy />} />
            <Route path="/liHa" element={<LiHa />} />
            <Route path="/lokNurse" element={<LokNurse />} />
            <Route path="/lokBscNurse" element={<LokBscNurse />} />
            <Route path="/bn" element={<Bn />} />
            <Route path="/mn" element={<Mn />} />
          </Routes>{" "}
        </div>
        <Message />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
