import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function BscNurse() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" shadow-md md:px-10 px-4 pb-4 flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col justify-center items-center text-black  font-bold md:text-2xl text-xl"
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <span className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            B.Sc. Nursing
          </span>
        </div>

        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          आदरणीय अभिभावक तथा विद्यार्थी वर्ग सर्वप्रथम नर्सिङ परीक्षा उत्तिर्ण
          गर्ने सबैलाई हार्दिक शुभकामना ! परीक्षा उत्तीर्ण भएका विद्यार्थीहरु
          आफ्नो व्यवसायीक जीवन प्रारम्भ गर्न चरणको एक कदमको दूरीमा हुनुहुन्छ ।
          त्यसको अन्तिम खुटकिलो पार गर्न विद्यार्थीहरुले नेपाल नर्सिङ काउन्सिल
          (Nepal Nursing Council)ले लिने दर्ता प्रमाण-पत्र अर्थात लाइसेन्स
          परीक्षामा पास गर्नुपर्ने हुन्छ । हामीले लाइसेन्सको प्रवेश परीक्षा सुरु
          भएदेखि नै त्यसको तयारी कक्षा सञ्चालन गर्दै आएका जानकारी गराउदछौं ।
          काउन्सिलले प्रत्येक ४-४ महिनाको अन्तरालमा लाइन्सेस परीक्षा सञ्चालन
          गर्छ । कुनै पनि विद्यार्थी लाइसेन्स परीक्षामा अनुत्तिर्ण भए ४ महिनामै
          पुन: लाइसेन्स परीक्षामा भाग लिन पाइन्छ । त्रिभुवन, काठमाडौं,
          पूर्वाञ्चल, पोखरा विश्वविद्यालयले लिने स्नातक तह नर्सिङ (B.Sc.
          Nursing) को अन्तिम परीक्षा लगत्तै विद्यार्थीले तयारी सुरु गरे लाइसेन्स
          परीक्षा पास गर्न सजिलो हुन्छ । लाइसेन्स परीक्षा १५० पूर्णाङ्कको
          बहु-विकल्पीय प्रश्न (Multiple Choice Questions)मा आधारित हुन्छ ।
          लाइसेन्स प्राप्त गर्नका लागि कम्तीमा ५० प्रतिशत अर्थात ७५ अंक ल्याउनु
          पर्छ ।
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          नर्सिङ काउन्सिलले लिने परीक्षाको प्रश्नहरु झुक्याउने प्रकृतिको
          (Tricky) हुने भएकाले विद्यार्थीलाई अफ्ठ्यारो लाग्न सक्छ । त्यही
          अफ्ठ्यारोलाई हटाउन रिलायवल एकेडेमीले ४ वर्षको कोर्षलाई छोटो अवधीमा
          रिभिजन गर्ने र परीक्षाका संभावित प्रश्नअनुसारको दैनिक रुपमा अभ्यास
          गर्न कक्षा सञ्चालन गर्ने गरेको छ । जसका लागि नर्सिङ काउन्सिलको
          पाठ्यक्रमअनुसार संस्थाले तयार पारेको पुस्तकमा आधारित भएर सम्बन्धित
          विषय विज्ञमार्फत अध्ययन/अध्यापनको व्यवस्था मिलाएका छौं । विद्यार्थीको
          अध्ययन र बुझाइको प्रक्रिया सरल र सहज बनाउनका लागि श्रव्य-दृश्य
          (Audio/Visual) विधिमार्फत अध्ययन गर्ने व्यवस्था गरेका छौं ।
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          तीव्र प्रतिस्पर्धाको कारण रजिस्टर्ड नर्स हुँदैमा रोजगार पाइन्छ भन्ने
          निश्चित हुँदैन, राम्रो स्वास्थ्य संस्थाहरुमा जागिर त्यति सहजै पनि छैन
          । त्यसलाई ध्यानदिँदै रिलायवल एकेडेमीले लाइसेन्सको परीक्षासँगसँगै
          विद्यार्थीका लागि लोकसेवा मात्रै नभएर वीर, टिचिङ, गंगालाल तथा अन्य
          केन्द्रिय अस्पतालहरुमा रोजगारीका लागि दिनु पर्ने परीक्षाको तयारी समेत
          गराउने गरेको छ । राम्रो तयारी विना कुनै पनि प्रतिस्पर्धात्मक परीक्षामा
          सफल हुन कठिन हुन्छ । रिलायवल एकेडेमीले प्रतिस्पर्धात्मक परीक्षाका लागि
          अध्ययन/अध्यापन गराउने तरिका समेत फरक रुपमा सञ्चालन गर्ने गरेको छ ।
          अनुभवी शिक्षकमार्फत वैज्ञानिक विधीहरुको प्रयोग गरेर सञ्चालन गरेका
          कक्षाहरु प्रभावकारी भएका छन् । जसको परिणाम यहाँ अध्ययन गरेको
          विद्यार्थीले गरेको सफलताले देखाएको छ । त्यसैले विद्यार्थी तथा
          अभिभावकलाई उचित समयमा सही इन्स्टिच्युटलाई छनोट गरेर आफ्नो भविष्य
          उज्ज्वल बनाउन आग्रह गर्दछौं 
        </div>
        <a
          href="/licence"
          className=" cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
