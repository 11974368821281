import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Entrance() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" shadow-md md:px-10 px-4 pb-4 flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex justify-center text-black  font-bold md:text-2xl text-xl"
        >
          ENTRANCE PREPRATION
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
          Navigating the path after SEE can be overwhelming for most students.
          In today’s fast paced world, many students gravitate towards technical
          subjects because of the learning and earning potential as well as
          quick job placements. Due to limited seats and high competition, only
          students who stand out in the entrance examination are chosen for
          further studies. Reliable Academy offers specialized entrance
          preparation courses for various fields such as Staff Nurse, HA,
          Radiography, Dental, Lab Assistant, Ophthalmic, Pharmacy, Overseer,
          IT, Computer, as well as +2 Science. We are committed to providing
          students with the best resources for a bright future. With our
          guidance and thorough preparation, students can confidently pursue
          their dreams and build a bright future.
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5  py-10"
        >
          <div className="   grid lg:grid-cols-4  md:grid-cols-3  gap-10 items-center">
            <Link
              to="/allCourses"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              CTEVT
            </Link>
            <Link
              to="/bn"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              BN
            </Link>
            <Link
              to="/mn"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              MN
            </Link>
            <Link
              to="/bridge"
              className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 cursor-pointer text-gray-100 rounded-md"
            >
              BRIDGE COURSE [+2]
            </Link>
          </div>
        </div>
        <Link
          to="/"
          className=" cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
