import React from "react";
import suraj from "../assets/sunil.jpg";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Sunil() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col gap-2 justify-center items-center "
        >
          <img
            className=" border px-4 py-5 cursor-pointer w-72 h-72 rounded-2xl object-cover"
            src={suraj}
            alt=""
          />
          <p className="  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Sunil Bhattarai
          </p>
          <p className=" md:text-[19px] text-[18px] text-gray-700">(Founder)</p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-100 rounded-md">
            Sunil Bhattarai
          </div>
          {/* <div className=" text-[17px] text-gray-700">
            आदरणिय अभिभावक तथा विद्याथी वर्ग, SEE पछिको Health Science विषय
            अन्तर्गत Staff Nurse, General Medicine (HA), Pharmacy, Lab
            Technician, Radiography, Dental, Ophthalmic, ANM, CMA, Lab Assistant
            Diploma in Engineering तर्फ Civil, Overseer, Mechanical, Electrical,
            Electronics Architect र Agriculture, Forestry जस्ता विषयको तयारी
            कक्षा गराई हामीले हजारौं छात्र तथा छात्राहरुको सफलतामा अग्रणी भूमिका
            खेल्दै आएकाछौँ । त्यस्तै +2 Science पढ्न चाहने विद्यार्थीहरुका लागि
            Bridge कोर्ष सञ्चालन गर्दै आएका छौं ।
          </div>
          <div className=" text-[17px] text-gray-700">
            समय परिवर्तन सँगै शिक्षा क्षेत्रमा विषय वस्तुको आमुल परिवर्तन हुँदै
            जानुले विद्यार्थी वर्गमा सदैव कडा प्रतिस्पर्धा रहने गरेको छ । SEE
            पछिको फुर्सदको समयलाई सहि सदुपयोग गरी आफूले रोजेको विषयमा प्रवेश
            पाउन विद्यार्थी वर्गले कडा मिहिनत गर्नै पर्छ । सोही आधारमा यस
            Reliable Academy मा लामो समय देखिका दक्ष तथा अनुभवी शिक्षक तथा
            शिक्षिकाहरुद्धारा तयारी कक्षा गराउँदै आएका छौँ ।
          </div>
          <div className=" text-[17px] text-gray-700">
            अन्त्यमा Reliable Academy संचालन गर्न प्रेरित गर्नुहुने आदरणीय
            अभिभावक, विद्यार्थी वर्ग, योग्य र क्षमतावान शिक्षक-शिक्षिका एवम्
            शुभचिन्तक मित्रहरुलाई हृदय देखि नैं आभार प्रकट गर्दै आगामी दिनहरुमा
            पनि यसरी नै हजुरहरुको महत्पूर्ण साथ, सुझाव अनि सहयोगको अपेक्षा राखि
            निरन्तर गुणस्तरीय सेवा प्रदान गर्न अग्रसर भइरहने प्रतिबद्धता व्यक्त
            गर्दछौ । बढ्दो प्रतिस्पर्धाका विच आफूले रोजेको विषयमा प्रवेश पाउँन
            राम्रो तयारीका लागि Reliable Academy मा आउन आग्रह गर्दै सम्पूर्ण
            विद्यार्थीहरुमा उज्ज्वल भविष्यको शुभकामना व्यक्त गर्दछौं ।
          </div> */}
          <div className=" flex flex-col gap-2">
            <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Contact Details
            </p>
            <p>Phone Number :- +977 9841761641</p>
            <p>Email :-  sunilbhattarairbl@gmail.com  </p>
            <p className="  mb-3">Address :- Putalisadak Kathmandu</p>
            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-100 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <Link
          to="/executive"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
