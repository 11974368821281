import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function LiHa() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96 rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            General Medicine (HA)
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
            To prepare for licensure in general medicine, focus on a strategic
            study plan covering key topics like anatomy, pharmacology, and
            clinical skills. Use practice exams to gauge your understanding and
            identify areas for improvement. Join study groups or review courses
            for collaborative learning and insights from peers. Develop
            test-taking strategies to manage time and stress during the exam.
            Remember to maintain a healthy lifestyle with regular breaks, proper
            nutrition, and sufficient sleep. A balanced approach that combines
            thorough study, consistent practice, and self-care will set you on
            the path to success.
          </div>
        </div>
        <a
          href="/licence"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
