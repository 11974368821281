import React from "react";
import girl from "../assets/gr.webp";
import CountUp from "react-countup";
import { counts } from "../data/CountUp";

export default function Count() {
  return (
    <div className=" overflow-x-hidden">
      <div className=" flex justify-center items-center  relative    bg-gradient-to-br  from-[#0F2C4E] to-primary-500 h-[70vh]   md:h-[50vh] w-full">
        <img
          src={girl}
          alt=""
          className=" overflow-x-hidden  object-cover absolute w-full h-full mix-blend-overlay"
        />

        <div className="  grid md:grid-cols-4 md:gap-24 gap-10 justify-center items-center">
          {counts.map((count, index) => (
            <div
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1500"
              key={index}
              className=" flex flex-col gap-1 items-center"
            >
              <CountUp
                end={count.number}
                start={0}
                duration={15}
                className="   text-gray-200  md:text-6xl text-5xl  font-semibold"
              >
                {count.number}
              </CountUp>
              <p className=" italic text-gray-300  md:text-2xl text-xl font-semibold">
                {count.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
