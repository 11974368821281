import React from "react";
import matari from "../assets/mata.png";
import { LuMoveLeft } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function Matarika() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col gap-2 justify-center items-center "
        >
          <img
            className=" border px-4 py-5 cursor-pointer w-72 h-72 rounded-2xl object-cover"
            src={matari}
            alt=""
          />
          <p className="  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            Matrika Bhattarai
          </p>
          <p className=" md:text-[19px] text-[18px] text-gray-700">
            (Founder/Director)
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-xl flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10 py-2 text-gray-200 rounded-md">
            Matrika Bhattarai
          </div>
          <div className=" text-[17px] text-gray-700">
            आदरणीय अभिभावक तथा विद्यार्थी वर्ग, सर्वप्रथम नर्सिङ्गको अन्तिम
            परीक्षामा सहभागी हुने सबै विद्यार्थीलाई हार्दिक शुभकामना ब्यक्त
            गर्दछौँ । आफ्नो व्यवसायीक जीवन प्रारम्भ गर्न चरणको एक कदमको दूरीमा
            हुनुहुन्छ । त्यसको अन्तिम खुटकिलो पार गर्न विद्यार्थीहरुले नेपाल
            नर्सिङ्ग काउन्सिल (Nepal Nursing Council)ले लिने दर्ता प्रमाण-पत्र
            अर्थात लाइसेन्स परीक्षामा पास गर्नुपर्ने हुन्छ। हामीले लाइसेन्सको
            प्रवेश परीक्षा सुरु भएदेखि नै त्यसको तयारी कक्षा सञ्चालन गर्दै आएको
            जानकारी गराउदछौं ।
          </div>
          <div className=" text-[17px] text-gray-700">
            काउन्सिलले प्रत्येक ४-४ महिनाको अन्तरालमा लाइन्सेस परीक्षा सञ्चालन
            गर्छ । कुनै पनि विद्यार्थी लाइसेन्स परीक्षामा अनुत्तिर्ण भए ४
            महिनामै पुनः लाइसेन्स परीक्षामा भाग लिन पाउँछ । प्राविधिक शिक्षा तथा
            व्यवसायीक तालिम परिषद् (CTEVT) ले लिने प्रमाणपत्र तह नर्सिङ्ग (PCL
            Nursing) को अन्तिम परीक्षा लगत्तै विद्यार्थीले तयारी सुरु गरे
            लाइसेन्स परीक्षा पास गर्न सजिलो हुन्छ । लाइसेन्स परीक्षा १५०
            पूर्णाङ्कको बहु-वैकल्पिक प्रश्नहरू (Multiple Choice Questions)मा
            आधारित हुन्छ । लाइसेन्स प्राप्त गर्नका लागि कम्तीमा ५० प्रतिशत
            अर्थात ७५ अंक ल्याउनु पर्छ ।
          </div>
          <div className=" text-[17px] text-gray-700">
            नर्सिङ्ग काउन्सिलले लिने परीक्षाको प्रश्नहरु झुक्याउने प्रकृतिको
            (Tricky) हुने भएकाले विद्यार्थीलाई अफ्ठ्यारो लाग्न सक्छ । त्यही
            अफ्ठ्यारोलाई हटाउन रिलायवल एकेडेमीले ३ वर्षको कोर्षलाई छोटो अवधिमै
            मुख्य विषयवस्तुमा केन्द्रीत राखेर छोटो र मिठो तरिकाले रिभिजन गराउँदै
            परीक्षाको सम्भावित प्रश्न अनुसारको दैनिक रुपमा अभ्यास गर्ने गरेको छ
            । जसका लागि नर्सिङ्ग काउन्सिलको पाठ्यक्रम अनुसार संस्थाले तयार
            पारेको पुस्तकमा आधारित भएर सम्बन्धित विषय विज्ञमार्फत अध्यापनको
            ब्यबस्था मिलाएका छौं। विद्यार्थीको अध्ययन र बुझाईको प्रक्रिया सरल र
            सहज बनाउनको लागि श्रब्य- दृश्य (Audio- Visual) विधिमार्फत अध्ययन
            गर्ने ब्यवस्था मिलाएका छौं । तीव्र प्रतिस्पर्धाको कारण रजिस्टर्ड
            नर्स हुँदैमा रोजगार पाइन्छ भन्ने निश्चित हुँदैन, राम्रो स्वास्थ्य
            संस्थाहरुमा जागिर त्यति सहज पनि छैन । त्यसलाई ध्यानदिँदै रिलायवल
            एकेडेमीले लाइसेन्सको परीक्षासँगसँगै विद्यार्थीका लागि लोकसेवा मात्रै
            नभएर वीर, टिचिङ, गंगालाल तथा अन्य केन्द्रिय अस्पतालहरुमा रोजगारीका
            लागि दिनु पर्ने परीक्षाको समेत तयारी गराउने गरेको छ । राम्रो तयारी
            विना कुनै पनि प्रतिस्पर्धात्मक परीक्षामा सफल हुन कठिन हुन्छ ।
            रिलायवल एकेडेमीले प्रतिस्पर्धात्मक परीक्षाका लागि अध्यापन गराउने
            तरिका समेत फरक रुपमा सञ्चालन गर्ने गरेको छ। अनुभवी शिक्षकमार्फत
            वैज्ञानिक विधीहरुको प्रयोग गरेर सञ्चालन गरेका कक्षाहरु प्रभावकारी
            भएका छन्। जसको परिणाम यहाँ अध्ययन गरेका विद्यार्थीले प्राप्त गरेको
            सफलताले देखाएको छ । त्यसैले विद्यार्थी तथा अभिभावकलाई उचित समयमा सही
            इन्स्टिच्युटलाई छनोट गरेर आफ्नो भविष्य उज्ज्वल बनाउन आग्रह गर्दछौं ।
          </div>
          <div className=" flex flex-col gap-2">
            <p className="  text-primary-500 md:text-2xl text-xl font-semibold">
              Contact Details
            </p>
            <p>Phone Number :- +977 9851020006</p>
            <p>Email :- matrikabhattarairbl@gmail.com </p>
            <p className="  mb-3">Address :- Putalisadak Kathmandu</p>
            <p className=" flex md:justify-start justify-center items-center bg-primary-550 hover:bg-primary-500 duration-500 md:w-max md:px-10  px-1 py-2 text-gray-200 rounded-md">
              Available Time :- Sunday - Friday 7AM - 5PM
            </p>
          </div>
        </div>
        <Link
          to="/executive"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </Link>
      </div>
    </div>
  );
}
