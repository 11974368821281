export const counts = [
  {
    number: 70,
    name: "Professional Teachers",
  },
  {
    number: 9,
    name: "  Years of Guidance",
  },
  {
    number: 12000,
    name: " Successful Students",
  },
  {
    number: 50,
    name: "   Expert Faculty Members",
  },
];
