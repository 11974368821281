import React from "react";

export default function Contact() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <main className=" flex flex-col gap-10 md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col justify-center"
        >
          <div className="max-w-xl space-y-6">
            <div className=" flex flex-col gap-1">
              <h3 className="  text-black  font-bold md:text-3xl text-2xl cursor-pointer">
                Contact Us :
              </h3>
              <span className="  text-black/90 font-bold md:text-xl text-xl cursor-pointer">
                Reliable Academy
              </span>
            </div>
            <p className=" text-[20px] text-gray-700">
              Certainly! Here is the contact information for Reliable Academy
            </p>
          </div>
          <div>
            <ul className="mt-12 flex flex-wrap gap-x-12 gap-y-6 items-center lg:gap-x-24">
              {contactMethods.map((item, idx) => (
                <li key={idx}>
                  <h4 className=" text-xl text-gray-800 font-medium hover:text-gray-500 duration-500  cursor-pointer ">
                    {item.title}
                  </h4>
                  <div className="mt-3 flex items-center gap-x-3">
                    <div className="flex-none  text-primary-400 font-semibold  duration-700">
                      {item.icon}
                    </div>
                    <p className=" md:text-[19px]  text-[18px] text-gray-700 cursor-pointer hover:text-gray-500 duration-200">
                      {item.contact}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" mt-10 flex justify-center items-center"
        >
          <iframe
            title="Description of the iframe content"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.445796249544!2d85.31923517517099!3d27.703518925660823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a851d21aa7%3A0x1514f22a42153099!2z4KS24KSC4KSV4KSw4KSm4KWH4KS1IOCkleCljeCkr-CkvuCkruCljeCkquCkuA!5e0!3m2!1sne!2snp!4v1713712301769!5m2!1sne!2snp"
            className=" md:w-[1500px] md:h-[500px] h-96 w-full"
          ></iframe>
        </div>
      </main>
    </div>
  );
}

const contactMethods = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
        />
      </svg>
    ),
    contact: "In front of Shanker Dev Campus, Putalisadak, Kathmandu",
    title: "Our office",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>
    ),
    contact: "01-4168305 9851020006 , 9841761641",
    title: "Phone",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    ),
    contact: "reliableacademy073@gmail.com",
    title: "Email",
  },
];
