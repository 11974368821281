import React from "react";

import re8 from "../assets/re8.jpg";
import re9 from "../assets/gr1.jpg";
import re10 from "../assets/st1.jpg";
import re7 from "../assets/gr7.jpg";
import re5 from "../assets/gr8.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

export default function Herosection() {
  return (
    <div className=" md:px-0 px-4   flex justify-center">
      <div className=" flex  md:w-11/12 w-full justify-center ">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {Ticets.map((Ticet, index) => (
            <SwiperSlide>
              <div
                key={index}
                className=" md:flex items-center justify-between md:px-10  px-1  "
              >
                <div
                  data-aos-duration="2000"
                  data-aos="fade-up"
                  className=" flex  items-center md:flex-1  flex-col gap-6"
                >
                  <h1 className=" flex justify-center md:text-3xl text-2xl text-black  font-bold">
                    {Ticet.type}
                  </h1>
                  <h2 className=" md:text-2xl  text-xl text-gray-700">
                    {Ticet.name}
                  </h2>
                  <Link to="/contact">
                    <div className=" flex justify-center  cursor-pointer  ">
                      <span className="  bg-primary-500  hover:bg-primary-550 text-white px-10 py-2  text-md hover:scale-105 duration-300  border rounded-md">
                        {Ticet.btn}
                      </span>
                    </div>
                  </Link>
                </div>
                <img
                  data-aos="fade-down"
                  data-aos-duration="2000"
                  className=" rounded-3xl  md:pr-20  md:h-96 md:pb-0 pb-10 md:flex-1 md:mt-0 mt-10  bg-transparent "
                  src={Ticet.img}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

const Ticets = [
  {
    type: "FEATURES 1.0",
    img: re8,
    name: " विद्यार्थीको Online फारम भरिदिने व्यवस्था.",
    btn: "CONTACT US",
  },
  {
    type: "FEATURES 2.0",
    img: re9,
    name: " आवश्यकता परेमा Hostel को ब्यवस्था.",
    btn: "CONTACT US",
  },
  {
    type: "FEATURES 3.0",
    img: re10,
    name: " हरेक दिन आधाघण्टाको Class Test.",
    btn: "CONTACT US",
  },
  {
    img: re5,
    type: "FEATURES 4.0",
    name: "दक्ष  तथा अनुभवी शिक्षक तथा शिक्षिकाद्वारा पढाईने.",
    btn: "CONTACT US",
  },
  {
    img: re7,
    type: "FEATURES 5.0",
    name: "  चार महिने कक्षा, प्रत्येक शनिवार Model परीक्षा",
    btn: "CONTACT US",
  },
];
