import React from "react";

import re6 from "../assets/re6.jpg";

import re8 from "../assets/re8.jpg";

import st1 from "../assets/st1.jpg";
import st2 from "../assets/st2.jpg";
import gr1 from "../assets/gr1.jpg";
import gr2 from "../assets/gr2.jpg";
import gr3 from "../assets/sir.jpg";
import gr4 from "../assets/gr4.jpg";
import gr5 from "../assets/gr5.jpg";
import gr6 from "../assets/gr6.jpg";

import gr8 from "../assets/gr8.jpg";
import gr9 from "../assets/gr9.jpg";

export default function Gallery() {
  return (
    <div className=" md:px-0 px-4 flex justify-center items-center">
      <div className=" flex  justify-center items-baseline md:w-10/12">
        <div className="  grid  lg:grid-cols-3 md:grid-cols-2 md:gap-12 gap-10">
          {images.map((image, index) => (
            <div key={index}>
              <img
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className=" cursor-pointer h-96 w-96 object-cover rounded-md"
                src={image.img}
                alt=""
              />
            </div>
          ))}
        </div>{" "}
      </div>
    </div>
  );
}

const images = [
  {
    img: st1,
  },
  {
    img: re8,
  },

  {
    img: gr1,
  },
  {
    img: gr2,
  },

  {
    img: gr3,
  },

  {
    img: re6,
  },

  {
    img: st2,
  },

  {
    img: gr4,
  },
  {
    img: gr5,
  },

  {
    img: gr6,
  },

  {
    img: gr8,
  },

  {
    img: gr9,
  },
];
