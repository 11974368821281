import React from "react";

import girl from "../assets/maa.png";

export default function QuickAnswer() {
  return (
    <div className=" md:px-0 px-4 flex flex-col md:gap-16 gap-12 justify-center items-center">
      <p
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="  cursor-pointer  text-black  font-bold md:text-3xl text-[22px]  curve-underline"
      >
        {" "}
        Our Frequently Asked Questions
      </p>
      <div className=" md:flex justify-between md:w-10/12 w-full">
        <div className=" md:flex-1">
          <div
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos="fade-left"
            className=" flex justify-center items-center bg-primary-550  rounded-t-full  md:pt-12  pt-5  md:w-max w-full  px-10 md:px-20"
          >
            <img
              className=" flex  rounded-3xl md:h-[50vh]  h-80 w-full object-cover cursor-pointer"
              src={girl}
              alt=""
            />{" "}
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:mt-0 mt-12 md:flex-1"
        >
          <div className=" space-y-4">
            <details
              className="group border-s-4 border-primary-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-700">
                  What programs does Reliable Academy offer ?
                </h2>

                <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>
              <p className="mt-3 leading-relaxed text-gray-600">
                We Offer :- +2 Bridge Course , PCL Nursing License Prepration ,
                B.Sc. Nursing License Prepration , CTEVT Programs (Staff Nurse ,
                HA , Pharmacy , Lab etc) Prepration , Lok Sewa Prepration ,
                BN(CEE) Prepration
              </p>
            </details>

            <details className="group border-s-4 border-primary-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-700">
                  How can i apply to Course ?
                </h2>

                <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.google.com/forms/d/1CeiSo4dx0kQUjQlz6QovEkQLgHaX6LvO4DjRbcdCIBw/edit"
                className="mt-3 leading-relaxed bg-primary-500  duration-500 hover:bg-primary-450 text-gray-100 cursor-pointer px-10 py-1 rounded-lg"
              >
                Online Form
              </a>
            </details>

            <details className="group border-s-4 border-primary-500 bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5">
                <h2 className="text-lg font-medium text-gray-700">
                  Does Reliable Academy  offer online courses ?
                </h2>

                <span className="shrink-0 rounded-full bg-white p-1.5 text-gray-900 sm:p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-45"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-3 leading-relaxed text-gray-600">
                Yes, Reliable provides online learning options, allowing
                students to access quality education from anywhere in the world.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
}
