import React from "react";
import staff from "../assets/sraff.jpg";
import eng from "../assets/engineering.webp";
import ha from "../assets/ha.jpg";
import frome from "../assets/forestry.jpg";
import phar from "../assets/pharmacy.jpg";
import lab from "../assets/lab.jpg";
import agri from "../assets/agi.webp";
import { Link } from "react-router-dom";

export default function AllCourses() {
  return (
    <div className=" md:px-0 px-4 flex flex-col md:gap-10 gap-6 justify-center items-center ">
      <h1
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className="  text-black  font-bold md:text-2xl text-xl  underline "
      >
        CTEVT ALL PROGRAMS
      </h1>
      <span
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className=" md:px-36 md:text-[19px] text-[17px] text-gray-700"
      >
        आदरणिय अभिभावक तथा विद्याथी वर्ग, SEE पछिको Health Science विषय अन्तर्गत
        Staff Nurse, General Medicine (HA), Pharmacy, Lab Technician,
        Radiography, Dental, Ophthalmic, Lab Assistant Diploma in Engineering
        तर्फ Civil, Overseer, Mechanical, Electrical, Electronics Architect र
        Agriculture, Forestry जस्ता विषयको तयारी कक्षा गराई हामीले हजारौं छात्र
        तथा छात्राहरुको सफलतामा अग्रणी भूमिका खेल्दै आएकाछौँ । त्यस्तै +2
        Science पढ्न चाहने विद्यार्थीहरुका लागि Bridge कोर्ष सञ्चालन गर्दै आएका
        छौं ।
      </span>
      <span
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
        className=" md:px-36 md:text-[19px] text-[17px] text-gray-700"
      >
        Note: CTEVT अन्तर्गतका Nursing, HA, Pharmacy, LAB Technician, Diploma in
        Engineering तथा अन्य विषयमा सरकारी/सामुदायिक विद्यालयमा पढेका छात्र तथा
        छात्राले सरकारी कोटामा नि: शुल्क पढ्न पाइन्छ। थप जानकारीको लागी Reliable
        Academy मा सम्पर्क राख्नुहुन अनुरोध गर्दछौं।
      </span>
      <div className=" flex justify-center items-center md:w-10/12">
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  grid lg:grid-cols-3 md:grid-cols-2  md:gap-14 gap-10"
        >
          {services.map((service, index) => (
            <div
              key={index}
              className=" rounded-md group bg-primary-550 hover:bg-primary-500 duration-500"
            >
              <img
                className="  h-52  w-full cursor-pointer object-cover"
                src={service.img}
                alt=""
              />
              <div className=" flex flex-col gap-3 px-5 py-6">
                <p className="  text-gray-300 md:text-2xl text-xl group-hover:text-gray-400 duration-200">
                  {service.name}
                </p>
                <span className=" text-gray-400 md:text-[17px] text-[16px]  group-hover:text-gray-300 ">
                  {service.desc}
                </span>
                <div className=" flex justify-between">
                  <span></span>
                  <Link
                    to={service.path}
                    className=" w-max bg-gray-200 px-4 py-1 rounded-md  hover:bg-gray-300 duration-500"
                  >
                    {service.btn}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const services = [
  {
    img: staff,
    name: "Staff Nurse",
    desc: "Staff Nurse नेपालमा छात्राहरुले मात्र पढ्न पाउने विषय थियो । ....",
    btn: "View Details",
    path: "/staffNurse",
  },
  {
    img: eng,
    name: "Diploma In Engineering",
    desc: "  SEE पछि तीन वर्षे कार्यक्रममा धेरै विद्यार्थीहरुका रोजाईमा Engineering ......",
    btn: "View Details",
    path: "/enginnering",
  },
  {
    img: ha,
    name: "General Medicine (HA)",
    desc: " HA छात्र तथा छात्रा दुवै वर्गले पढ्न पाउने विषय हो । HA को पढाई ३ वर्षको हुने गर्दछ । ...",
    btn: "View Details",
    path: "/ha",
  },
  {
    img: frome,
    name: "Diploma In Forestry",
    desc: "Diploma in Forestry को अध्ययन विद्यार्थीहरुका लागि रुचाइएको विषय हुने गर्दछ । ......",
    btn: "View Details",
    path: "/forestry",
  },
  {
    img: phar,
    name: "D. Pharmacy",
    desc: "फार्मेसी CTEVT बाट सम्बन्धन पाएका Colleges मा मात्र पढ्न पाईन्छ । ३ वर्षको अध्ययन पछि ......",
    btn: "View Details",
    path: "/pharmacy",
  },
  {
    img: lab,
    name: "Lab Technician",
    desc: "Lab Technician को महत्वपूर्ण स्थान रहेको छ । SEE पछि रोजगारीको विषयमा धेरै छात्र/छात्राहरुको......",
    btn: "View Details",
    path: "/labTechnician",
  },

  {
    img: agri,
    name: "Diploma In Agriculture",
    desc: " शिक्षामा कृषि शिक्षाको पनी महत्व रहदै आएको छ । SEE पछि कृषि अध्ययन ३ वर्षको हुन्छ ।......",
    btn: "View Details",
    path: "/agriculture",
  },
];
