import React from "react";
import { LuMoveLeft } from "react-icons/lu";
import staff from "../assets/sraff.jpg";

export default function Bridge() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className=" shadow-md md:px-10 px-4 pb-4 flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col justify-center items-center text-black  font-bold md:text-2xl text-xl"
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <span className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
          BRIDGE COURSE [+2]
          </span>
        </div>

        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" md:text-[19px] text-[17px] text-gray-700"
        >
         As students move from SEE to pursuing +2 Science, it is essential to focus on all science subjects. Rather than taking a break, it is advisable to consider enrolling in entrance preparation courses known as Bridge courses. Bridge courses provide consistent practice, enhancing the chances of success in the entrance examination. Many +2 colleges conduct entrance examinations for incoming grade 11 students. Joining bridge courses significantly aids in exam preparation and increases the probability of achieving high scores. Most +2 colleges offer scholarships and discounts based on entrance exam performance, emphasizing the importance of bridge courses. Reliable Academy offers specialized bridge course classes customized for students pursuing +2 Science. These classes offer a complete focus on subjects such as science, mathematics and English, establishing a strong foundation to tackle any challenges presented in the entrance examination.
        </div>
        <a
          href="/entrance"
          className=" cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
