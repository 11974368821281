import React from "react";
import staff from "../assets/sraff.jpg";
import { LuMoveLeft } from "react-icons/lu";

export default function Bn() {
  return (
    <div className="  md:px-0 px-4 flex justify-center items-center">
      <div className="  flex flex-col md:gap-8 gap-4 justify-center md:w-10/12 w-full">
        <div
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="  flex flex-col gap-2 justify-center items-center "
        >
          <img
            className="   border px-4 py-5 cursor-pointer  w-full  h-96  rounded-2xl object-cover"
            src={staff}
            alt=""
          />
          <p className=" underline  mt-2 flex justify-center items-center  text-xl text-gray-700 font-semibold">
            BN
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className=" flex flex-col md:gap-8 gap-5 shadow-md md:px-10 px-4 py-10"
        >
          <div className=" text-[17px] text-gray-700">
          The Medical Education Commission (MEC) conducts an annual entrance examination for BNS (Bachelor in Nursing) with 200 questions out of which 180 are Health based and remaining 20 are MAT based. Candidates must achieve a minimum of 50th percentile in order to pass this examination. Whether candidates opt to study in Nepal or abroad, passing this examination is mandatory. Reliable Academy offers specialized classes including free books and necessary resources. We offer premium quality classes led by a skilled and experienced teacher’s team. Candidates can greatly benefit from our entrance preparation courses.
          </div>
        </div>
        <a
          href="/entrance"
          className="  mt-2 cursor-pointer hover:text-gray-700 flex  gap-1 items-center justify-center"
        >
          <LuMoveLeft size={25} className=" text-gray-800" />
          <p className=" text-[18px]">Back</p>
        </a>
      </div>
    </div>
  );
}
